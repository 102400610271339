.footer {
    padding-top: 3rem;
    padding-bottom: 3rem;

    @include breakpoint(XS) {
        padding-top: 2.75rem;
        padding-bottom: 2.75rem;
    }
    h3 {
        display: flex;
        justify-content: center;
        align-items: center;
        a {
            margin-left: 0.5rem;

            svg {
                margin-left: 1.5rem;
            }
        }
        
        @include breakpoint(M) {
            flex-direction: column;
            padding: 0rem 2rem;
            
            a {
                display: flex;
                flex-direction: column;
                margin-top: 2rem;
                margin-left: 0;

                svg {
                    margin-top: 2rem;
                    margin-left: 0;
                }
            }
        }
    }
    .footer-menu {
        ul {
            li {
                border: none;
                a {
                    
                }
            }
        }
    }
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 2.5rem;

        @include breakpoint(XS) {
            flex-direction: column;
            align-items: center;
            li {
                width: 50%;
            }
        }
        a {
            margin: 0.5rem 1rem;
            font-weight: 400;
            @include color(color, 'body');
            display: block;

            &:hover {
                @include color(color, 'accent-primary');
            }
        }
    }
}

.social-links {
    .button.secondary.icon {
        margin: 0 0.5rem;
        width: 3.5rem;
        height: 3.5rem;
        @include color(border-color, 'background-50');
    }
}