.wp-block-gallery {
    padding-left: 9rem;
    padding-right: 9rem;
    justify-content: center;

    padding-top: 3rem;
    padding-bottom: 3rem;

    + .wp-block-gallery {
        padding-top: 0;
        margin-top: -3rem;

        @include breakpoint(XS) {
            margin-top: -2rem;
        }
    }

    @include breakpoint(L) {
        padding-left: 7.5rem;
        padding-right: 7.5rem;
    }
    @include breakpoint(ML) {
        padding-left: 5rem;
        padding-right: 5rem;
    }
    @include breakpoint(S) {
        padding-left: 3.5rem;
        padding-right: 3.5rem;
    }
    @include breakpoint(XS) {
        padding-left: 1.75rem;
        padding-right: 1.75rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    &.alignfull {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    ul {
        padding: 0;
        margin: 0;
        
        li {
            padding: 0;
            margin: 0;
    
            &:before {
                display: none;
            }
        }
    }
}